import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  LOGOUT_SUCCESS,
  LOAD_DATA,
  LOAD_DATA_QUESTIONS,
  LOAD_MAP,
  LOAD_USERS,
  UPDATE_CREDITS,
  REQUEST_RESET,
  REQUEST_ACTIVATION,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_DATA,
  SAVE_TEST,
  UPDATE_USER_ADMIN,
  LOAD_DONE_TESTS,
  CLEAR_CURRENT_DONE_TEST,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  currentUser: null,
  data: [],
  mapping: [],
  dataQuestions: [],
  currentDoneTest: [],
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        currentUser: { email: action.payload.email },
        isAuthenticated: true,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case REQUEST_RESET:
    case REQUEST_ACTIVATION:
    case RESET_PASSWORD:
    case RESET_PASSWORD_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        currentUser: null,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload,
      };
    case LOAD_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case LOAD_DATA_QUESTIONS:
      let newDataObject = Object.assign(state.dataQuestions, {
        ...action.payload,
      });
      return {
        ...state,
        dataQuestions: newDataObject,
      };

    case LOAD_DONE_TESTS:
      return {
        ...state,
        currentDoneTest: action.payload,
      };

    case CLEAR_CURRENT_DONE_TEST:
      let tmp = [];
      return {
        ...state,
        currentDoneTest: tmp,
      };

    case LOAD_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SAVE_TEST:
      return {
        ...state,
        isAuthenticated: true,
        doneTests: action.payload,
      };
    case RESET_DATA:
      return {
        ...state,
        data: [],
      };
    case LOAD_MAP:
      return {
        ...state,
        mapping: action.payload,
      };
    case UPDATE_CREDITS:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload,
      };
    case UPDATE_USER_ADMIN:
      return {
        ...state,
      };
    default:
      return state;
  }
};
