export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const LOAD_DATA = "LOAD_DATA";
export const LOAD_QUESTIONS = "LOAD_QUESTIONS";
export const LOAD_MAP = "LOAD_MAP";
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_DATA_QUESTIONS ="LOAD_DATA_QUESTIONS";
export const AUTH_ERROR = "AUTH_ERROR";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const UPDATE_CREDITS = "UPDATE_CREDITS";
export const UPDATE_USER_ADMIN = "UPDATE_USER_ADMIN";
export const UPDATE_FAIL = "UPDATE_FAIL";
export const ACTIVATION = "ACTIVATION";
export const REQUEST_RESET = "REQUEST_RESET";
export const REQUEST_ACTIVATION = "REQUEST_ACTIVATION";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_DATA = "RESET_DATA";
export const SAVE_TEST = "SAVE_TEST";
export const LOAD_DONE_TESTS ="LOAD_DONE_TESTS";
export const CLEAR_CURRENT_DONE_TEST ="CLEAR_CURRENT_DONE_TEST";
