import React from "react";
import { Typography } from "@material-ui/core";

export const NoMatch = () => {
  // Show 404 message if url don't match any routes
  return (
    <>
      <Typography>
        <div> 404 </div>
        <h1>
          <span role="img">Faqja nuk gjendet</span>
        </h1>
      </Typography>
    </>
  );
};
export default NoMatch;
