import axios from "axios";

export default {
  // API request to server side
  register(data) {
    return axios.post("/auth/register", data);
  },
  requestReset(data) {
    return axios.post("/auth/requestReset", data);
  },
  resetPassword(data) {
    return axios.put("/auth/resetPassword", data);
  },
  login(data) {
    return axios.post("/auth/login", data);
  },
  loadUser(headers) {
    return axios.get("/auth/user", headers);
  },
  loadUsers(headers) {
    return axios.get("/auth/users", headers);
  },
  requestActivation(headers) {
    return axios.put("/auth/requestActivation", headers);
  },
  loadQuestions(headers) {
    return axios.get("/auth/question", headers);
  },
  loadQuestionsResults(headers) {
    return axios.get("/auth/questionResults", headers);
  },

  loadSequenceQuestions(headers) {
    return axios.get("/auth/questionSequence", headers);
  },

  loadDoneTests(headers){
    return axios.get("/auth/doneTests", headers);
  },

  loadMappings(headers) {
    return axios.get("/auth/mapping", headers);
  },
  updateCredits(data) {
    return axios.put("/auth/updateCredits", data);
  },
  updateUserAdmin(data) {
    return axios.put("/auth/updateUser", data);
  },
  updateTests(data) {
    return axios.put("/auth/updateTest", data);
  },
  activateAccount(data) {
    return axios.put("/auth/activate", data);
  },
};
