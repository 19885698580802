import API from "../utils/API";
import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  LOAD_DATA,
  AUTH_ERROR,
  USER_LOADED,
  GET_ERRORS,
  CLEAR_ERRORS,
  LOAD_MAP,
  LOAD_USERS,
  UPDATE_CREDITS,
  UPDATE_FAIL,
  ACTIVATION,
  REQUEST_ACTIVATION,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_DATA,
  SAVE_TEST,
  UPDATE_USER_ADMIN,
  LOAD_DONE_TESTS,
  LOAD_DATA_QUESTIONS,
  CLEAR_CURRENT_DONE_TEST,
} from "./types";
import history from "../history";

// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
// Action to register new users
export const registerUser = (data) => async (dispatch) => {
  try {
    // send request to server side to register user
    const mail = data.email.toLowerCase();
    const repeatemail = data.repeatemail.toLowerCase();
    data.email = mail;
    data.repeatemail = repeatemail;

    const response = await API.register(data);
    // dispatch to reducer
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });
    // redirect to page
    history.push("/quizpage");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch register fail
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    // send request to server side to register user
    const response = await API.resetPassword(data);
    // dispatch to reducer
    dispatch({
      type: RESET_PASSWORD,
      payload: response.data,
    });
    // redirect to page
    history.push("/login");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch register fail
    dispatch({
      type: RESET_PASSWORD_FAIL,
    });
  }
};

// Action to login users
export const loginUser = (data) => async (dispatch) => {
  try {
    // send request to server side to login user
    var mail = data.email.toLowerCase().trim();
    data.email = mail;
    const response = await API.login(data);
    // dispatch to reducer
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    // redirect to page
    history.push("/quizpage");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch login fail
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
// Action to load current user
export const loadUser = () => async (dispatch, getState) => {
  try {
    // call getTokenImfo funtion and store content (geader information) to headers
    const headers = getTokenInfo(getState);
    // send request to server side and pass token information in headers to verify token
    const user = await API.loadUser(headers);
    // dispatch to reducer
    dispatch({
      type: USER_LOADED,
      payload: user.data,
    });
  } catch (err) {
    // dispatch Auth error
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const requestActivation = () => async (dispatch, getState) => {
  try {
    // call getTokenImfo funtion and store content (geader information) to headers
    const headers = getTokenInfo(getState);
    // send request to server side and pass token information in headers to verify token
    var data = { headers: headers };
    const user = await API.requestActivation(data);

    // dispatch to reducer
    dispatch({
      type: REQUEST_ACTIVATION,
      payload: user.data,
    });
  } catch (err) {
    // dispatch Auth error
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const requestReset = (data) => async (dispatch) => {
  try {
    // call getTokenImfo funtion and store content (geader information) to headers
    // send request to server side and pass token information in headers to verify token
    const user = await API.requestReset(data);
    // dispatch to reducer
    dispatch({
      type: USER_LOADED,
      payload: user.data,
    });
  } catch (err) {
    // dispatch Auth error
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// function to configure token to be used on any server request using the server side "auth" middleware
export const getTokenInfoCharset = (getState) => {
  const token = getState().auth.token;
  const headerConfig = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  };
  if (token) {
    headerConfig.headers["token"] = token;
  }

  return headerConfig;
};

// function to configure token to be used on any server request using the server side "auth" middleware
export const getTokenInfo = (getState) => {
  const token = getState().auth.token;
  const headerConfig = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    headerConfig.headers["token"] = token;
  }

  return headerConfig;
};
// Action to logout user
export const LogoutUser = () => {
  // redirect to page
  history.push("/");
  // dispatch logout success
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const resetCurrentDoneTest = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CURRENT_DONE_TEST,
  });
};

export const loadQuestions =
  (data, nr, showPD) => async (dispatch, getState) => {
    try {
      // call getTokenImfo funtion and store content (geader information) to headers
      var headers = getTokenInfo(getState);
      const questionType = {
        questionType: data,
        nrQuestions: nr,
        showPD: showPD,
      };
      headers.params = questionType;
      // send request to server side and pass token information in headers to verify token
      const questions = await API.loadQuestions(headers);
      // dispatch to reducer
      dispatch({
        type: LOAD_DATA,
        payload: questions.data,
      });
    } catch (err) {
      // dispatch Auth error
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const loadDoneTests = (data) => async (dispatch, getState) => {
  try {
    // call getTokenImfo funtion and store content (geader information) to headers
    var headers = getTokenInfo(getState);
    const questionType = { testData: data };
    headers.params = questionType;

    // send request to server side and pass token information in headers to verify token
    const questions = await API.loadDoneTests(headers);
    // dispatch to reducer
    dispatch({
      type: LOAD_DONE_TESTS,
      payload: questions.data,
    });
  } catch (err) {
    // dispatch Auth error
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadUsers = () => async (dispatch, getState) => {
  try {
    // call getTokenImfo funtion and store content (geader information) to headers
    var headers = getTokenInfo(getState);
    // send request to server side and pass token information in headers to verify token
    const questions = await API.loadUsers(headers);
    // dispatch to reducer
    dispatch({
      type: LOAD_USERS,
      payload: questions.data,
    });
  } catch (err) {
    // dispatch Auth error
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadSequenceQuestions =
  (data, nr, startIndex) => async (dispatch, getState) => {
    try {
      // call getTokenImfo funtion and store content (geader information) to headers
      var headers = getTokenInfo(getState);
      const questionType = {
        questionType: data,
        nrQuestions: nr,
        startIndex: startIndex,
      };
      headers.params = questionType;
      // send request to server side and pass token information in headers to verify token
      const questions = await API.loadSequenceQuestions(headers);
      // dispatch to reducer
      dispatch({
        type: LOAD_DATA,
        payload: questions.data,
      });
    } catch (err) {
      // dispatch Auth error
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const loadQuestionsResults =
  (data, start, nr) => async (dispatch, getState) => {
    try {
      // call getTokenImfo funtion and store content (geader information) to headers
      var headers = getTokenInfo(getState);
      const questionType = {
        questionType: data,
        startingAt: start,
        nrQuestions: nr,
      };
      headers.params = questionType;
      // send request to server side and pass token information in headers to verify token
      const questions = await API.loadQuestionsResults(headers);
      // dispatch to reducer
      dispatch({
        type: LOAD_DATA_QUESTIONS,
        payload: questions.data,
      });
    } catch (err) {
      // dispatch Auth error
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const loadMappings = () => async (dispatch, getState) => {
  try {
    // call getTokenImfo funtion and store content (geader information) to headers
    const headers = getTokenInfo(getState);
    // send request to server side and pass token information in headers to verify token
    const mappings = await API.loadMappings(headers);
    // dispatch to reducer
    dispatch({
      type: LOAD_MAP,
      payload: mappings.data,
    });
  } catch (err) {
    // dispatch Auth error
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const resetData = () => async (dispatch) => {
  dispatch({
    type: RESET_DATA,
  });
};

export const updateCredits = (nr) => async (dispatch, getState) => {
  try {
    var data = { nr: nr };
    const headers = getTokenInfo(getState);
    data.headers = headers;
    // send request to server side and pass token information in headers to verify token
    const response = await API.updateCredits(data);
    // dispatch to

    dispatch({
      type: UPDATE_CREDITS,
      payload: response.data,
    });

    // redirect to page
  } catch (err) {
    // dispatch get error Action
    //console.log("Error At Action");

    dispatch(getErrors(err.response.data));
    dispatch({
      type: UPDATE_FAIL,
    });
  }
};

export const updateUserAdmin = (user) => async (dispatch, getState) => {
  try {
    var data = { user: user };
    const headers = getTokenInfo(getState);
    data.headers = headers;
    // send request to server side and pass token information in headers to verify token
    const response = await API.updateUserAdmin(data);
    // dispatch to

    dispatch({
      type: UPDATE_USER_ADMIN,
      payload: response.data,
    });

    //history.push("/admindashboard");
    // redirect to page
  } catch (err) {
    // dispatch get error Action
    //console.log("Error At Action");

    dispatch(getErrors(err.response.data));
    dispatch({
      type: UPDATE_FAIL,
    });
  }
};

export const updateTests = (test) => async (dispatch, getState) => {
  try {
    var data = { test: test };
    const headers = getTokenInfo(getState);
    data.headers = headers;
    // send request to server side and pass token information in headers to verify token
    const response = await API.updateTests(data);
    // dispatch to

    dispatch({
      type: SAVE_TEST,
      payload: response.data,
    });

    // redirect to page
  } catch (err) {
    // dispatch get error Action
    //console.log("Error At Action");

    dispatch(getErrors(err.response.data));
    dispatch({
      type: UPDATE_FAIL,
    });
  }
};

export const activateAccount = (data) => async (dispatch) => {
  try {
    // send request to server side to login user
    const response = await API.activateAccount(data);

    // dispatch to reducer
    dispatch({
      type: ACTIVATION,
      payload: response.data,
    });
    // redirect to page
    history.push("/quizpage");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch login fail
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
