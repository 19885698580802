import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logout from "./Logout";
import {
  AppBar,
  Toolbar,
  Button,
  Chip,
  Hidden,
  MenuItem,
  Menu,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core/";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import { withStyles } from "@material-ui/styles";
import MenuIcon from "@material-ui/icons/Menu";

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: { color: "#fefefe" },
  title: {
    flexGrow: 1,
  },
};

export const NavBar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth);
  return (
    <AppBar>
      <Toolbar>
        <img
          alt="mesimonline Provimet e licenses online"
          style={{ height: 35,  border: 1, marginHorizontal: 16}}
          src={process.env.PUBLIC_URL + "/images/mesim.svg"}
        />

        <a href="https://www.facebook.com/mesim.site">
          <img
            alt="Na ndiqni ne Facebook"
            style={{ height: 35 , marginLeft: 16}}
            src={process.env.PUBLIC_URL + "/images/facebook.svg"}
          />
        </a>

        <a href="https://www.instagram.com/mesim.site">
          <img
            alt="Na ndiqni ne Instagram"
            style={{ height: 40, border: 1 , marginLeft: 14}}
            src={process.env.PUBLIC_URL + "/images/instagram.svg"}
          />
        </a>

        <Hidden only={["xs", "sm"]}>
          <Button color="white" component={Link} to="/">
            Home
          </Button>
        </Hidden>

        <Hidden only={["xs", "sm"]}>
          <Button
            color="white"
            component={Link}
            to="/dashboard"
          >
            Bli
          </Button>
        </Hidden>

        {isAuthenticated && (
          <Hidden only={["xs", "sm"]}>
            <Button
              color="white"
              component={Link}
              to="/quizpage"
            >
              Testim
            </Button>
          </Hidden>
        )}

        {isAuthenticated && (
          <Hidden only={["xs", "sm"]}>
            <Button
              color="white"
              component={Link}
              to="/sequence"
            >
              Pyetjet me rradhe
            </Button>
          </Hidden>
        )}

        {isAuthenticated && (
          <Hidden only={["xs", "sm"]}>
            <Button
              color="white"
              component={Link}
              to="/answers"
            >
              Pergjigjet
            </Button>
          </Hidden>
        )}

        {isAuthenticated && (
          <>
            <Hidden only={["xs", "sm"]}>
              <Button
                color="white"
                component={Link}
                to="/results"
              >
                Rezultate{" "}
              </Button>
            </Hidden>
          </>
        )}

        {isAuthenticated &&
          currentUser.paid === true &&
          currentUser.admin === true && (
            <Hidden only={["xs", "sm"]}>
              <Button
                color="white"
                component={Link}
                to="/admindashboard"
              >
                Admin
              </Button>
            </Hidden>
          )}

        <Box sx={{ p: 1, flexGrow: 1 }}></Box>

        <Hidden only={["xs", "sm"]}>
          {isAuthenticated ? (
            <>
              <Chip
                icon={<EmojiEmotionsIcon />}
                label={"Pa limit"}
                variant="default"
                color="primary"
              />

              <Button
                color="white"
                component={Link}
                to="/dashboard"
              >
                {currentUser.name + " " + currentUser.surname}
              </Button>

              <Logout />
            </>
          ) : (
            <>
              <Button
                color="white"
                component={Link}
                to="/login"
              >
                Login
              </Button>

              <Button
                color="white"
                component={Link}
                to="/register"
              >
                Regjistrohu
              </Button>
            </>
          )}
        </Hidden>

        <Hidden only={["xl", "lg", "md"]}>
          <IconButton
            style={{ color: "white" }}
            variant="contained"
            aria-label="add to shopping cart"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isAuthenticated && (
          <MenuItem>
            <Chip
              icon={<EmojiEmotionsIcon />}
              label={"Pa limit"}
              variant="default"
              color="primary"
            />
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem component={Link} to="/" onClick={handleClose}>
            <Typography variant="body1">Home</Typography>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem component={Link} to="/dashboard" onClick={handleClose}>
            <Typography variant="body1">Bli</Typography>
          </MenuItem>
        )}

        {isAuthenticated && (
          <MenuItem component={Link} to="/quizpage" onClick={handleClose}>
            <Typography variant="body1">Testim</Typography>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem component={Link} to="/sequence" onClick={handleClose}>
            <Typography variant="body1">Pyetjet me rradhe</Typography>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem component={Link} to="/results" onClick={handleClose}>
            <Typography variant="body1">Rezultate</Typography>
          </MenuItem>
        )}

        {isAuthenticated && (
          <MenuItem component={Link} to="/answers" onClick={handleClose}>
            <Typography variant="body1">Pyetjet me pergjigje</Typography>
          </MenuItem>
        )}

        {isAuthenticated && (
          <MenuItem component={Link} to="/dashboard" onClick={handleClose}>
            <Typography variant="body1">{currentUser.name}</Typography>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem>
            <Logout onClick={handleClose} />
          </MenuItem>
        )}
        {!isAuthenticated && (
          <MenuItem component={Link} to="/" onClick={handleClose}>
            Home
          </MenuItem>
        )}
        {!isAuthenticated && (
          <MenuItem component={Link} to="/login" onClick={handleClose}>
            Login
          </MenuItem>
        )}
        {!isAuthenticated && (
          <MenuItem component={Link} to="/register" onClick={handleClose}>
            Regjistrohu
          </MenuItem>
        )}
        {isAuthenticated &&
          currentUser.admin === true && (
            <MenuItem
              component={Link}
              to="/admindashboard"
              onClick={handleClose}
            >
              <Typography variant="body1">Admin</Typography>
            </MenuItem>
          )}
      </Menu>
    </AppBar>
  );
};

export default withStyles(styles)(NavBar);
