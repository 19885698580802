import React, { useEffect, Suspense, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";
import { useDispatch } from "react-redux";
import { loadUser } from "../actions/authActions";
import NavBar from "./NavBar";

import PrivateRoute from "./PrivateRoute";
import PaidRoute from "./PaidRoute";
import AdminRoute from "./AdminRoute";
import NoMatch from "../pages/NoMatch";
import { withStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core/";
import { ThemeProvider } from '@mui/material/styles';
import theme from "../theme";
const styles = {
  root: { marginTop: 48 },
};

const Home = lazy(() => import('../pages/Home'));
const UserDashboard = lazy(() => import('../pages/UserDashboard'));
const Activation = lazy(() => import('../pages/Activation'));
const Terms = lazy(() => import('../pages/Terms'));
const Admin = lazy(() => import('../pages/Admin'));
const Results = lazy(() => import('../pages/Results'));
const PasswordReset = lazy(() => import('../pages/PasswordReset'));
const QuizPage = lazy(() => import('../pages/QuizPage'));
const SequencePage = lazy(() => import('../pages/Sequence'));
const Answers = lazy(() => import('../pages/Answers'));
const Login = lazy(() => import('../pages/Login'));
const Register = lazy(() => import('../pages/Register'));






export const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>

        <NavBar />
        <Grid
          container
          direction="column"
          style={{ marginTop: 80, padding: 16 }}
        >
          <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/activation/:email" component={Activation} />
            <Route path="/terms" component={Terms} />
            <Route path="/resetPassword/:token" component={PasswordReset} />
            <PrivateRoute path="/dashboard" component={UserDashboard} />
            <PrivateRoute path="/quizpage" component={QuizPage} />
            <PrivateRoute path="/answers" component={Answers} />
            <AdminRoute path="/admindashboard" component={Admin} />
            <PrivateRoute path="/sequence" component={SequencePage} />
            <Route path="/results" component={Results} />
            <Route component={NoMatch} />
          </Switch>
          </Suspense>
        </Grid>
      </Router>
    </ThemeProvider>
  );
};

export default withStyles(styles)(App);
