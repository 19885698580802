import React from "react";
import { LogoutUser } from "../actions/authActions";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core/";
import { withStyles } from "@material-ui/styles";

const styles = {
  menuButton: { color: "#fefefe" },
};

const Logout = (props) => {
  const dispatch = useDispatch();

  return (
    <Button
      variant="contained"
      color="secondary"
      href="/"
      style={{ border: "none", cursor: "pointer" }}
      onClick={() => {
        dispatch(LogoutUser());
      }}
    >
      Logout
    </Button>
  );
};

export default withStyles(styles)(Logout);
