import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoute = ({ component: Component, ...rest }) => {
  // access isAuthenticated from state auth
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const hasPaid = useSelector((state) => state.auth.currentUser);
  return (
    <Route
      {...rest}
      render={(props) =>
        //if isAuthenticated and has paid
        isAuthenticated && hasPaid.paid && hasPaid.admin  ? (
          <Component {...props} />
        ) : (
          // else redirect to the login page
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
